import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { acceptAgencyRedeem } from "../../../store/agencyRedeem/action";
import { CLOSE_BONUS_PANELTY_DIALOGUE } from "../../../store/agencyRedeem/type";

const BonusPaneltyDialogue = () => {
  const dispatch = useDispatch();

  const {
    dialog1: open,
    dialogData1,
    setting,
  } = useSelector((state) => state.agencyRedeem);
  const getAgency = JSON.parse(localStorage.getItem("AGENCY"));
  const [mongoId, setMongoId] = useState("");
  const [bonus, setBonus] = useState("");
  const [penalty, setPenalty] = useState("");
  const [type, setType] = useState(1);
  const [errors, setError] = useState({
    penalty: "",
    bonus: "",
  });

  useEffect(() => {
    
    setMongoId(dialogData1?.id);
    if (dialogData1?.bonusOrPenalty <= 0) {
      setPenalty(dialogData1?.bonusOrPenalty);
    } else {
      setBonus(dialogData1?.bonusOrPenalty);
    }
  }, [dialogData1]);

  useEffect(() => {
    setError({
      bonus: "",
      penalty: "",
    });
    setMongoId("");
    setBonus("");
    setPenalty("");
  }, [dialogData1]);

  const closePopup = () => {
    dispatch({ type: CLOSE_BONUS_PANELTY_DIALOGUE });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      Math.abs(dialogData1?.finalCoin) < parseInt(penalty) ||
      (!bonus && !penalty)
    ) {
      const error = {};
      if (!bonus && !penalty) error.penalty = "Penalty/Bonus is required!";
      if (!bonus && !penalty) error.bonus = "Penalty/Bonus is required!";
      if (Math.abs(dialogData1?.finalCoin) < parseInt(penalty))
        error.penalty = `No penalty can be imposed more than the ${dialogData1?.finalCoin} `;
      return setError({ ...error });
    }
    const parsedPenalty = parseInt(penalty);
    
    dispatch(
      acceptAgencyRedeem(dialogData1?.id, "accept", bonus, parsedPenalty,"")
    );
    closePopup();
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4">
            Redeem Request
          </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="col-md-6 d-flex justify-content-start">
                  <label className="mb-2 text-gray">Select Type : </label>
                  <div class="form-check">
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="type"
                      id="bonus"
                      value={2}
                      onClick={(e) => {
                        setType(e.target.value);
                      }}
                      checked={type == 2 ? true : false}
                    />
                    <label class="form-check-label" for="bonus">
                      Bonus
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input mx-2"
                      type="radio"
                      name="type"
                      id="penalty"
                      value={1}
                      checked={type == 1 ? true : false}
                      onClick={(e) => {
                        setType(e.target.value);
                      }}
                    />
                    <label class="form-check-label" for="penalty">
                      Penalty
                    </label>
                  </div>
                </div>
                <div className="row">
                  {type == 2 && (
                    <div className={`col-md-12 `}>
                      <div className="form-group">
                        <label className="text-gray mb-2">Bonus</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Bonus"
                          value={bonus}
                          disabled={penalty !== null && penalty?.length > 0}
                          onFocus={(e) =>
                            e.target.value == 0 ? setBonus("") : ""
                          }
                          onChange={(e) => {
                            setBonus(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                bonus: "Bonus is Required!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                bonus: "",
                              });
                            }
                          }}
                        />
                        {errors.bonus && (
                          <div className="ml-2 mt-1">
                            {errors.bonus && (
                              <div className="pl-1 text__left">
                                <span className="text-red">{errors.bonus}</span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {type == 1 && (
                    <div className={`ol-md-12 mt-2 `}>
                      <div className="form-group">
                        <label className="text-gray mb-2">Penalty</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Penalty"
                          disabled={bonus !== null && bonus?.length > 0}
                          onFocus={(e) =>
                            e.target.value == 0 ? setPenalty("") : ""
                          }
                          value={penalty}
                          onChange={(e) => {
                            setPenalty(e.target.value);
                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                penalty: "Penalty is Required!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                penalty: "",
                              });
                            }
                          }}
                        />
                        {errors.penalty && (
                          <div className="ml-2 mt-1">
                            {errors.penalty && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.penalty}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BonusPaneltyDialogue;
