import React from "react";

const UserCoinSellerHistory = (props) => {
  return (
    <table class="table table-striped mt-5">
      <thead>
        <tr>
          <th>No.</th>
          <th>Coin</th>
          <th>Date</th>
          <th>Time</th>
         
        </tr>
      </thead>
      <tbody>
        {props.data?.length > 0 ? (
          props.data.map((data, index) => {
                  var gameDate = data?.date ? data?.date.split(",") : [];
            return (
              <tr key={index}>
                <td>{(props.activePage - 1) * props.rowsPerPage + index + 1}</td>

                <td className="text-primary">
                  {data.diamond > 0 && data.diamond}
                </td>
            
                <td className="text-info">{gameDate[0]}</td>
                <td className="text-primary">{gameDate[1]}</td>

           
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="8" align="center">
              Nothing to show!!
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default UserCoinSellerHistory;
