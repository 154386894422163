export const GET_USER = "GET_USER";
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";

export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";

export const GET_LIVE_USER = "GET_LIVE_USER";

export const LIVE_CUT = "LIVE_CUT";
export const EDIT_NAME = "EDIT_NAME";

export const IS_ROOM_ADMIN_SWITCH = "IS_ROOM_ADMIN_SWITCH";
