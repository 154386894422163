import { GET_REQUEST, ACCEPT_REQUEST} from "./types";

const initialState = {
  request: [],
};

const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST:
      
      return {
        ...state,
        request: action.payload,
      };

    case ACCEPT_REQUEST:
      return {
        ...state,
        request: state.request.filter(
          (request) => request._id !== action?.payload?._id
        ),
      };

    default:
      return state;
  }
};

export default requestReducer;
