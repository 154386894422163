import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types

import Male from "../../assets/images/male.png";
import $ from "jquery";
import { CLOSE_AGENCY_DIALOG } from "../../store/agency/type";
import { createAgency, editAgency } from "../../store/agency/action";

const AgencyDialogue = () => {
  const dispatch = useDispatch();
  const hasPermission = useSelector((state) => state.admin.admin.flag);
  const admin = useSelector((state) => state.admin);

  const {
    dialog: open,
    dialogData,
    agency,
  } = useSelector((state) => state.agency);

  const [imageData, setImageData] = useState([]);
  const [imagePath, setImagePath] = useState(null);
  const [mongoId, setMongoId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  
  const [password, setPassword] = useState({
    value: "",
    show: false,
  });
  const [code, setCode] = useState("");

  const [errors, setError] = useState({
    name: "",
    email: "",
    password: "",
    code: "",
    image: "",
  });

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData?._id);
      setName(dialogData?.name);
      setImagePath(dialogData?.image);
      setEmail(dialogData?.email);
      setPassword({ value: dialogData?.password });
      setCode(dialogData?.agencyCode);
    }
  }, [dialogData]);

  const removeImage = () => {
    setImageData([]);
    setImagePath(null);
  };
  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  useEffect(
    () => () => {
      setError({
        name: "",
        email: "",
        password: "",
        code: "",
        image: "",
      });
      setMongoId("");
      setName("");
      setEmail("");
      setPassword("");
      setCode("");
      setImageData([]);
      setImagePath(null);
    },
    [open]
  );

  const handleInputImage = (e) => {
    if (e.target.files[0]) {
      setImageData(e.target.files[0]);
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    if (!e.target.files[0]) {
      return setError({
        ...errors,
        image: "Please select an Image!",
      });
    } else {
      return setError({
        ...errors,
        image: "",
      });
    }
  };

  const handleShowPassword = () => {
    setPassword({ ...password, show: !password.show });
  };

  const createCode = () => {
    const randomChars = "0123456789";
    let code_ = "";
    for (let i = 0; i < 4; i++) {
      code_ += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
      setCode(code_);
    }
    if (!code_) {
      return setError({
        ...errors,
        code: "Code can't be a blank!",
      });
    } else {
      return setError({
        ...errors,
        code: "",
      });
    }
  };

  const createPassword = () => {
    const randomChars = "0123456789";
    let pass = "";
    for (let i = 0; i < 10; i++) {
      pass += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
      setPassword({ value: pass });
    }
    if (!pass) {
      return setError({
        ...errors,
        password: "Password can't be a blank!",
      });
    } else {
      return setError({
        ...errors,
        password: "",
      });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };


  const isEmail = (value) => {
    const val = value === "" ? 0 : value;
    const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      val
    );
    return emailValid;
  };

  const handleSubmit = (e) => {
    const emailValid = isEmail(email);
    if (
      !name ||
      !email ||
      !password.value ||
      !code ||
      !emailValid ||
      (!dialogData && !imagePath) 
    ) {
      const errors = {};
      if (!name) {
        errors.name = "Name can't be a blank!";
      }
      if (!email) {
        errors.email = "Email can't be blank!";
      } else if (!emailValid) {
        errors.email = "Invalid email address!";
      }
      if (!password.value) {
        errors.password = "Password can't be a blank!";
      }
      if (!code) {
        errors.code = "Code can't be a blank!";
      }
      

      if (!dialogData && !imagePath) {
        errors.image = "Please select an Image!";
      }

      return setError({ ...errors });
    }
    if (code?.toString()?.length > 4) {
      return setError({
        ...errors,
        code: "Maximum 4 Digits are Allowed!",
      });
    }

    if (code?.toString()?.length < 4) {
      return setError({
        ...errors,
        code: "Minimum 4 Digits are Allowed!",
      });
    }

    if (!mongoId) {
      const index = agency?.findIndex(
        (agency) => agency?.code?.toString() === code
      );
      if (index > -1) {
        return setError({ ...errors, code: "Code already exist." });
      }
      // if (imageData.length === 0 || !imagePath) {
      //   return setError({ ...errors, image: "Please select an Image!" });
      // }
    } else {
      const index = agency?.find((agency) => agency?.code?.toString() === code);
      if (index !== undefined) {
        if (index?._id === mongoId) {
        } else {
          return setError({ ...errors, code: "Code already exist." });
        }
      }
      // if (imageData?.length == 0 || !imagePath) {
      //   return setError({ ...errors, image: "Please select an Image!" });
      // }
    }

   

    const formData = new FormData();
    formData.append("image", imageData);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password.value);
    formData.append("agencyCode", code);

    if (mongoId) {
      dispatch(editAgency(formData, mongoId));
    } else {
      dispatch(createAgency(formData));
    }
    closePopup();
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_AGENCY_DIALOG });
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        // maxWidth="400px"
        sx={{ maxWidth: "400px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Agency </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div class="form-group col-12 ">
                    <label class="mb-2 text-gray">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Name"
                      required
                      value={name}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => {
                        setName(e.target.value.trim());

                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            name: "Name can't be a blank!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            name: "",
                          });
                        }
                      }}
                    />
                    {errors.name && (
                      <div className="ml-2 mt-1">
                        {errors.name && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.name}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-12 mt-4">
                    <label className="mb-2 text-gray">Image</label>
                    <input
                      type="file"
                      className="form-control form-control-sm"
                      accept="image/jpg ,image/jpeg ,image/png"
                      required=""
                      onChange={handleInputImage}
                    />
                    {errors.image && (
                      <div className="ml-2 mt-1">
                        {errors.image && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{errors.image}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="form-group col-12  mt-4">
                    {imagePath && (
                      <>
                        <img
                          height="70px"
                          width="70px"
                          alt="app"
                          src={imagePath}
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                            // border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "cover",
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div class=" form-group mt-3">
                  <div className="row">
                    <div class="col-md-12">
                      <label class="mb-2 text-gray">Email</label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter Email"
                        required
                        value={email}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setEmail(e.target.value.trim());

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              email: "Email can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              email: "",
                            });
                          }
                        }}
                      />
                      {errors.email && (
                        <div className="ml-2 mt-1">
                          {errors.email && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.email}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                 
                  </div>
                </div>
                <div class="row d-flex mt-3">
                  <div class={`${mongoId ? "col-12" : "col-md-9"}`}>
                    <div class="form-group">
                      <label class="mb-2 text-gray">Password</label>
                      <div class="input-group mb-3">
                        <div
                          class="input-group-prepend"
                          style={{
                            position: "absolute",
                            top: "0",
                            zIndex: "9999",
                          }}
                        >
                          <span
                            class="input-group-text password-icon"
                            id="basic-addon1"
                          >
                            {password.show ? (
                              <i
                                class="fas fa-eye icon"
                                onClick={handleShowPassword}
                              ></i>
                            ) : (
                              <i
                                class="fas fa-eye-slash icon"
                                onClick={handleShowPassword}
                              ></i>
                            )}
                          </span>
                        </div>
                        <input
                          type={password.show ? "text" : "password"}
                          class="form-control"
                          placeholder="Enter Password"
                          required
                          onKeyPress={handleKeyPress}
                          style={{ marginLeft: "45px" }}
                          value={password.value}
                          onChange={(e) => {
                            setPassword({ value: e.target.value });

                            if (!e.target.value) {
                              return setError({
                                ...errors,
                                password: "Password can't be a blank!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                password: "",
                              });
                            }
                          }}
                        />
                      </div>
                      {errors.password && (
                        <div className="ml-2 mt-1">
                          {errors.password && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.password}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {!mongoId && (
                    <div
                      class="col-md-3 pl-0 d-flex justify-content-end align-items-center"
                      style={{ marginTop: "22.01px" }}
                    >
                      <button
                        type="button"
                        class="btn btn-info"
                        style={{
                          borderRadius: 5,
                          fontSize: "14px",
                          padding: "8px",
                        }}
                        onClick={createPassword}
                      >
                        Auto Generate
                      </button>
                    </div>
                  )}
                </div>
                <div class="row d-flex">
                  <div class={`${mongoId ? "col-12" : "col-md-9"}`}>
                    <div class="form-group">
                      <label class="mb-2 text-gray">Agency Code</label>
                      <input
                        readOnly
                        type="number"
                        class="form-control"
                        placeholder="Enter Code"
                        required
                        value={code}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setCode(e.target.value);

                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              code: "Code can't be a blank!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              code: "",
                            });
                          }
                        }}
                      />
                      {errors.code && (
                        <div className="ml-2 mt-1">
                          {errors.code && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.code}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {!mongoId && (
                    <div
                      class="col-md-3 pl-0 d-flex justify-content-end align-items-center"
                      style={{ marginTop: "22.01px" }}
                    >
                      <button
                        type="button"
                        class="btn btn-info"
                        style={{
                          borderRadius: 5,
                          fontSize: "14px",
                          padding: "8px",
                        }}
                        onClick={createCode}
                      >
                        Auto Generate
                      </button>
                    </div>
                  )}
                </div>
                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AgencyDialogue;
