import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyProfile } from "../../store/agency/action";
import { Link } from "react-router-dom/cjs/react-router-dom";

const AgencyDashboard = () => {
  const dispatch = useDispatch();
  const agencyProfile = useSelector((state) => state.agency.agencyProfile);

  const agency = localStorage.getItem("AGENCY");

  useEffect(() => {
    dispatch(getAgencyProfile(JSON.parse(agency)?._id));
  }, [dispatch,agency]);
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Dashboard</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/agencyPanel/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="col-12 ">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6">
            <Link to="/agencyPanel/user">
              <div class="card stats-card">
                <div class="card-body pointer-cursor">
                  <div class="stats-info">
                    <h5 class="card-title">
                      {agencyProfile?.totalAgencyWiseUser ? agencyProfile?.totalAgencyWiseUser : 0}
                     
                    </h5>
                    <p class="stats-text">Total User</p>
                  </div>
                  <div class="stats-icon change-danger">
                    <i class="material-icons">people_alt</i>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div class="col-12 col-md-6 col-lg-6">
            <div class="card stats-card">
              <div class="card-body pointer-cursor">
                <div class="stats-info">
                  <h5 class="card-title">
                    {agencyProfile?.totalCoin
                      ? agencyProfile?.totalCoin
                      : 0}
                    
                  </h5>
                  <p class="stats-text">Total Coin</p>
                </div>
                <div class="stats-icon change-success">
                  <i class="material-icons">account_balance_wallet</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="card stats-card">
              <div class="card-body pointer-cursor">
                <div class="stats-info">
                  <h5 class="card-title">
                    {agencyProfile?.pendingWithdrawableCoin
                      ? agencyProfile?.pendingWithdrawableCoin
                      : 0}
                    
                  </h5>
                  <p class="stats-text">Pending Withdraw Coin</p>
                </div>
                <div class="stats-icon change-success">
                  <i class="material-icons">account_balance_wallet</i>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="card stats-card">
              <div class="card-body pointer-cursor">
                <div class="stats-info">
                  <h5 class="card-title">
                    {agencyProfile?.pendingWithdrawableRequestCoin
                      ? agencyProfile?.pendingWithdrawableRequestCoin
                      : 0}
                    
                  </h5>
                  <p class="stats-text">Pending Withdraw Requested Coin</p>
                </div>
                <div class="stats-icon change-success">
                  <i class="material-icons">account_balance_wallet</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyDashboard;
