import React, { useEffect, useRef, useState } from "react";
import AcceptedRequest from "./AcceptedRequest";
import DeclineRequest from "./DeclineRequest";
import PendingRequest from "./PendingRequest";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { baseURL } from "../../../util/Config";
import { UNSET_ADMIN } from "../../../store/admin/types";
 
const HostRequest = () => {
  const [type, setType] = useState("Pending");
  const socketRef = useRef(null);
  const { admin } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const ipAddress = localStorage.getItem("ipAddress");


  useEffect(() => {
    if (ipAddress.length > 0) {
      const socket = io.connect(baseURL, {
        transports: ["websocket", "polling", "flashsocket"],
        query: {
          adminRoom: admin && admin?._id,
        },
      });
      socketRef.current = socket ? socket : null;
      socketRef.current.on("connect", () => {
        console.log("Socket connected");
        // socketRef.current.emit("ticket", ipAddress && ipAddress);
      });
      socketRef.current.on("checkIpAdress", (data) => {
        console.log("data",data);
        if (data !== ipAddress) {
          dispatch({ type: UNSET_ADMIN });
        }
      });
      socketRef.current.on("disconnect", () => {
        console.log("Socket disconnected");
      });
      socketRef.current.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });
      return () => {
        console.log("Cleaning up socket connection");
        socketRef.current.disconnect();
      };
    }
  }, [ipAddress]);
  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Host Request</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Redeem
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Pending Redeem
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="my-2 mb-4">
            <button
              type="button"
              className={`btn btn-sm ${
                type === "Pending" ? "btn-info" : "disabledBtn"
              }`}
              onClick={() => setType("Pending")}
            >
              <span className="">Pending</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm ${
                type === "Accepted" ? "btn-danger" : "disabledBtn"
              } ms-3`}
              onClick={() => setType("Accepted")}
            >
              <span className="">Accepted</span>
            </button>
            <button
              type="button"
              className={`btn btn-sm ${
                type === "Declined" ? "btn-success" : "disabledBtn"
              } ms-3`}
              onClick={() => setType("Declined")}
            >
              <span className="">Declined</span>
            </button>
          </div>
        </div>
      </div>
      {type === "Pending" && (
        <>
          <PendingRequest />
        </>
      )}
      {type === "Accepted" && (
        <>
          <AcceptedRequest />
        </>
      )}
      {type === "Declined" && (
        <>
          <DeclineRequest />
        </>
      )}
    </>
  );
};

export default HostRequest;
