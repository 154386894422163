import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCommission } from "../../store/commission/action";
import arraySort from "array-sort";
import { Link } from "react-router-dom/cjs/react-router-dom";

const AgencyCommissionTable = () => {
  const dispatch = useDispatch();
  const commission = useSelector((state) => state.commission.commission);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [coinSort, setCoinSort] = useState(true);

  useEffect(() => {
    dispatch(getCommission());
  }, []);

  useEffect(() => {
    setData(commission);
  }, [commission]);

  const handleCoinSort = () => {
    setCoinSort(!coinSort);
    arraySort(data, "upperCoin", { reverse: coinSort });
  };

  return (
    <>
      <div className="page-title ">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white">Agency Commission</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/agencyPanel/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Agency Commissions
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0"></div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped custom-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      Upper Coin {coinSort ? " ▼" : " ▲"}
                    </th>
                    <th>Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{(page - 1) * rowsPerPage + index + 1}</td>
                          <td>{data.upperCoin}</td>
                          <td className="text-success">
                            {data.amountPercentage + "%"}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgencyCommissionTable;
