import axios from "axios";
import { Toast } from "../../util/Toast";

import { apiInstanceFetch } from "../../util/api";
import { GET_REQUEST, ACCEPT_REQUEST } from "./types";

export const getRequest = (type) => (dispatch) => {
  apiInstanceFetch
    .get(`verificationRequest?type=${type}`)
    .then((res) => {
      if (res.status) {
        
        dispatch({ type: GET_REQUEST, payload: res.profileIdVerifyRequest });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};

export const acceptRequest = (id, type) => (dispatch) => {
  axios
    .patch(`verificationRequest/${id}?type=${type}`)
    .then((res) => {
      if (res.data.status) {
        if (type === 3) return Toast("error", "Decline Success!!");
        if (type === 2) return Toast("success", "Accept Success!!");
        dispatch({ type: ACCEPT_REQUEST, payload: res.data.profileIdVerifyRequest });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
      Toast("error", error.message);
    });
};
