import React, { useEffect, useState } from "react";
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_BANNER_DIALOG } from "../../store/banner/types";

//action
import { addCoinSeller, editCoinSeller } from "../../store/coinSeller/action";
import { baseURL } from "../../util/Config";
import { permissionError } from "../../util/Alert";
import { CLOSE_DIALOGUE } from "../../store/coinSeller/type";

const CoinSellerAdd = (props) => {
  const dispatch = useDispatch();

  const { dialogOpen: open, dialogData } = useSelector(
    (state) => state.coinSeller
  );
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const [uniqueId, setUniqueId] = useState("");
  const [coin, setCoin] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errors, setError] = useState({
    uniqueId: "",
    coin: "",
    mobileNumber: ""
  });

  useEffect(() => {
    setUniqueId("");
    setCoin("")
    setMobileNumber("");
    setError({
      name: "",
      coin: "",
      countryCode:"",
      mobileNumber: ""
    });
  }, [open]);

  useEffect(() => {
    if (dialogData) {
      setUniqueId(dialogData?.name);
      setCoin(dialogData?.coin);
      setCountryCode(dialogData?.countryCode)
      setMobileNumber(dialogData?.mobileNumber)
    }
  }, [dialogData]);

  useEffect(() => {
    window.onbeforeunload = closePopup();
  }, []);

  const closePopup = () => {
    dispatch({ type: CLOSE_DIALOGUE });
  };

  const handleSubmit = () => {
    const mobileRegex = /^[0-9]{10}$/;
    if (!uniqueId || !coin || !mobileNumber || !countryCode) {
      const error = {};
      if (!uniqueId) error.uniqueId = "UniqueId Is Required !";
      if (!coin) error.coin = "Coin Is Required !";
      if (!countryCode) error.countryCode = "Country Code Is Required!";
      if (!mobileNumber) error.mobileNumber = "Mobile Number Is Required !";
      return setError({ ...error });
    } else if (!mobileRegex.test(mobileNumber)) {
      return setError({ mobileNumber: "Invalid Mobile Number !" });
    } else {
      const formData = new FormData();
      formData.append("uniqueId", uniqueId);
      formData.append("coin", coin);
      formData.append("countryCode", countryCode);
      formData.append("mobileNumber", mobileNumber);

      const data = {
        uniqueId: uniqueId,
        coin: coin,
        countryCode: countryCode,
        mobileNumber: mobileNumber
      }
      // if (dialogData) {
      //   props.editCoinSeller(dialogData._id,data);
      // } else {
      props.addCoinSeller(data);
      // }
      dispatch({ type: CLOSE_DIALOGUE });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Coin Seller </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Unique Id</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="User Unique Id"
                        value={uniqueId}
                        onChange={(e) => {
                          setUniqueId(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              uniqueId: "UniqueId is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              uniqueId: "",
                            });
                          }
                        }}
                      />
                      {errors.uniqueId && (
                        <div className="ml-2 mt-1">
                          {errors.uniqueId && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.uniqueId}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className=" form-group">
                      <label className="mb-2 mt-3 text-gray">Coin</label>
                      <input
                        type="number"
                        className="form-control"
                        required=""
                        placeholder="Coin"
                        value={coin}
                        onChange={(e) => {
                          setCoin(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              coin: "Coin is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              coin: "",
                            });
                          }
                        }}
                      />
                      {errors.coin && (
                        <div className="ml-2 mt-1">
                          {errors.coin && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.coin}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                  <div className="form-group" style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <div style={{ width: "100px", marginRight: "10px" }}>
                    <label className="mb-2 text-gray">Code</label>
                    <input
                      type="number"

                      className="form-control"
                      required=""
                      placeholder="91"
                      value={countryCode}
                      onChange={(e) => {
                        setCountryCode(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            countryCode: "Code is Required!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            countryCode: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <div style={{width:"100%"}}>
                    <label className="mb-2 text-gray">Mobile Number</label>
                    <input
                      type="number"
                      className="form-control"
                      required=""
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                        if (!e.target.value) {
                          return setError({
                            ...errors,
                            mobileNumber: "Mobile Number is Required!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            mobileNumber: "",
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                {errors.countryCode && (
                    <div className="ml-2 mt-1">
                      {errors.countryCode && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.countryCode}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {errors.mobileNumber && (
                    <div className="ml-2 mt-1">
                      {errors.mobileNumber && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.mobileNumber}</span>
                        </div>
                      )}
                    </div>
                  )}
         
                  </div>
                </div>
                <div className={"mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { addCoinSeller, editCoinSeller })(CoinSellerAdd);
