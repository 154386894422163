import React, { useEffect } from "react";
import {
  useHistory,
  useRouteMatch,
  Switch,
  Route,
} from "react-router-dom/cjs/react-router-dom";
import Navbar from "../../component/navbar/Navbar";
import Topnav from "../../component/navbar/Topnav";
import AgencyDashboard from "./AgencyDashboard";
import AgencyWiseUser from "./AgencyWiseUser";
import AgencyProfile from "./AgencyProfile";
import AgencyCommissionTable from "./AgencyCommissionTable";
import UserRedeem from "./userRedeem/UserRedeem";
import MyRedeem from "./Myredeem";
import NavbarAgency from "../../component/navbar/NavbarAgency";
import TopNavAgency from "../../component/navbar/TopNavAgency";

const AgencyMain = () => {
  const location = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (
      history.location.pathname === "/agencyPanel/dashboard" ||
      history.location.pathname === "/agencyPanel"
    ) {
      history.push("/agencyPanel/dashboard");
    }
  }, []);

  return (
    <>
      <div class="page-container">
        <NavbarAgency />
        <div class="page-content">
          <TopNavAgency />
          <div class="main-wrapper">
            <Switch>
              <Route
                path={`${location.path}/dashboard`}
                exact
                component={AgencyDashboard}
              />
              <Route
                exact
                path={`${location.path}/user`}
                component={AgencyWiseUser}
              />
              <Route
                path={`${location.path}/userRedeem`}
                exact
                component={UserRedeem}
              />
              <Route
                exact
                path={`${location.path}/commission`}
                component={AgencyCommissionTable}
              />
              <Route
                exact
                path={`${location.path}/agencyProfile`}
                component={AgencyProfile}
              />
              <Route
                path={`${location.path}/myRedeem`}
                exact
                component={MyRedeem}
              />
            </Switch>
            {/* <Spinner /> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default AgencyMain;
