import axios from "axios";
import { apiInstanceFetch } from "../../util/api";
import {
  ACTIVE_INACTIVE_AGENCY,
  CLOSE_AGENCY_DIALOG,
  CREATE_NEW_AGENCY,
  EDIT_AGENCY,
  GET_AGENCY,
  GET_AGENCY_PROFILE,
  GET_AGENCY_WISE_HOST,
  SET_AGENCY,
} from "./type";
import { Toast } from "../../util/Toast";
import { key } from "../../util/Config";

export const loginAgency = (data) => (dispatch) => {
  
  axios
    .post(`agency/login?key=${key}`, data)
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        Toast("success", "You have successfully logged in Gopi Live & voice Chat Room.");
        setTimeout(() => {
          window.location.href = "/agencyPanel/dashboard";
        }, 10);
        dispatch({ type: SET_AGENCY, payload: res.data.token });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const getAgencyProfile = (id) => (dispatch) => {
  apiInstanceFetch
    .get(`agency/getAgencyProfile?agencyId=${id}`)
    .then((res) => {
      if (res.status) {
        
        dispatch({ type: GET_AGENCY_PROFILE, payload: res?.data });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

// ----------------------------------------------admin panel ----------------------------------------------

export const getAgency = (start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`agency/index?start=${start}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: GET_AGENCY,
        payload: { agency: res.data, total: res.total },
      });
    })
    .catch((error) => console.log(error));
};

export const createAgency = (formData) => (dispatch) => {
  axios
    .post("agency/store", formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: CREATE_NEW_AGENCY, payload: res.data.data });
        dispatch({ type: CLOSE_AGENCY_DIALOG });
        Toast("success", "Agency Create Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const editAgency = (formData, id) => (dispatch) => {
  axios
    .patch(`agency/update?agencyId=${id}`, formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_AGENCY,
          payload: { data: res.data.data, id },
        });
        dispatch({ type: CLOSE_AGENCY_DIALOG });
        Toast("success", "Agency Update Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const activeInActiveAgency = (id) => (dispatch) => {
  axios
    .patch(`agency/activeOrNot?agencyId=${id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ACTIVE_INACTIVE_AGENCY, payload: res.data.data });
        Toast(
          "success",
          `${
            res.data?.data?.isActive === true
              ? "Active Successfully"
              : "InActive Successfully"
          }`
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const getAgencyWiseHost = (id, start, limit) => (dispatch) => {
  apiInstanceFetch
    .get(`agency/agencyWiseUser?agencyId=${id}&start=${start}&limit=${limit}`)
    .then((res) => {
      dispatch({
        type: GET_AGENCY_WISE_HOST,
        payload: { data: res?.data, total: res?.total },
      });
    })
    .catch((error) => console.log(error));
};
