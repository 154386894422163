import { Tooltip } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { permissionError } from "../../util/Alert";
//routing
import { Link, useHistory } from "react-router-dom";
//pagination
import Pagination from "../../pages/Pagination";
//image
import Male from "../../assets/images/male.png";
import { connect, useDispatch, useSelector } from "react-redux";

import { getLiveUser, liveCut } from "../../store/user/action";
import { io } from "socket.io-client";
import { UNSET_ADMIN } from "../../store/admin/types";
import { baseURL } from "../../util/Config";

const LiveUserTable = (props) => {
  const { liveUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");
  const socketRef = useRef(null);
  const { admin } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const ipAddress = localStorage.getItem("ipAddress");


  useEffect(() => {
    if (ipAddress.length > 0) {
      const socket = io.connect(baseURL, {
        transports: ["websocket", "polling", "flashsocket"],
        query: {
          adminRoom: admin && admin?._id,
        },
      });
      socketRef.current = socket ? socket : null;
      socketRef.current.on("connect", () => {
        console.log("Socket connected");
        // socketRef.current.emit("ticket", ipAddress && ipAddress);
      });
      socketRef.current.on("checkIpAdress", (data) => {
        console.log("data",data);
        if (data !== ipAddress) {
          dispatch({ type: UNSET_ADMIN });
        }
      });
      socketRef.current.on("disconnect", () => {
        console.log("Socket disconnected");
      });
      socketRef.current.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
      });
      return () => {
        console.log("Cleaning up socket connection");
        socketRef.current.disconnect();
      };
    }
  }, [ipAddress]);

  useEffect(() => {
    props.getLiveUser();
  }, []);

  useEffect(() => {
    setData(liveUser);
  }, [liveUser]);

  const handleBlockUnblockSwitch_ = (data) => {
    props.liveCut(data.liveStreamingId, data?.liveUserId?._id, data?.username);
  };

  const history = useHistory();

  const handleUserInfo = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/detail");
  };

  const handleUserHistory = (user) => {
    console.log("user", user);
    history.push({
      pathname: "/admin/user/history",
      state: { id: user?.liveUserId?._id, name: user?.username },
    });
  };

  //   pagination

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3" style={{ color: "#e4eeff" }}>
              Live User
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  User Live
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0"></div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Gender</th>
                    {/* <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      VCoin {coinSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>VCoin</th>
                    <th>Country</th>
                    <th>Level</th>
                    <th>Follower</th>
                    {/* <th
                      onClick={handleFollowerSort}
                      style={{ cursor: "pointer" }}
                    >
                      Follower {followerSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>Following</th>
                    {/* <th
                      onClick={handleFollowingSort}
                      style={{ cursor: "pointer" }}
                    >
                      Following {followingSort ? " ▼" : " ▲"}
                    </th> */}
                    <th>isLiveCut</th>
                    <th>Info</th>
                    <th>History</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.image ? data?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                float: "left",
                                objectFit: "cover",
                              }}
                            />
                          </td>
                          <td>{data.name}</td>
                          <td>{data?.liveUserId?.gender}</td>
                          <td className="text-danger">{data?.rCoin}</td>
                          <td className="text-success">{data?.country}</td>
                          <td className="text-warning">
                            {data?.liveUserId?.level?.name}
                          </td>
                          <td>{data?.liveUserId?.followers}</td>
                          <td>{data?.liveUserId?.following}</td>
                          <td>
                            <Tooltip title="Live Cut">
                              <button
                                type="button"
                                className="btn btn-sm btn-warning"
                                onClick={() => handleBlockUnblockSwitch_(data)}
                              >
                                Live Cut
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Info">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleUserInfo(data)}
                              >
                                <i className="fas fa-info-circle fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="History">
                              <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => handleUserHistory(data)}
                              >
                                <i className="fas fa-history fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={data?.length}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getLiveUser, liveCut })(LiveUserTable);
