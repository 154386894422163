import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getSetting } from "../../../store/setting/action";
import arraySort from "array-sort";
import dayjs from "dayjs";
import Male from "../../../assets/images/male.png";
import Pagination from "../../../pages/Pagination";
import { getAgencyRedeem } from "../../../store/agencyRedeem/action";
const TablePaginationActions = React.lazy(() =>
  import("../TablePaginationActions")
);

const AcceptedRedeem = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [coinSort, setCoinSort] = useState(true);

  useEffect(() => {
    dispatch(getSetting());
    dispatch(getAgencyRedeem("accept", activePage, rowsPerPage));
  }, [dispatch, activePage, rowsPerPage]);

  const { agencyRedeem, total } = useSelector((state) => state.agencyRedeem);
  const setting = useSelector((state) => state.setting.setting);

  useEffect(() => {
    setData(agencyRedeem);
  }, [agencyRedeem]);

  //   pagination

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.trim().toUpperCase()
      : e.target.value.trim();
    if (value) {
      const data = agencyRedeem.filter((data) => {
        return (
          data?.userId?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.paymentGateway?.toUpperCase()?.indexOf(value) > -1 ||
          data?.description?.toUpperCase()?.indexOf(value) > -1 ||
          data?.rCoin?.toString()?.indexOf(value) > -1 ||
          data?.agency?.agencyCode?.toString()?.indexOf(value) > -1 ||
          data?.agency?.uniqueId?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(agencyRedeem);
    }
  };

  const handleCoinSort = () => {
    setCoinSort(!coinSort);
    arraySort(data, "rCoin", { reverse: coinSort });
  };

  return (
    <div class="row">
      <div class="col">
        <div class="card">
          <div className="card-header pb-0">
            <div className="row my-3">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                <form action="">
                  <div className="input-group mb-3 border rounded-pill">
                    <div className="input-group-prepend border-0">
                      <div id="button-addon4" className="btn text-danger">
                        <i className="fas fa-search mt-2"></i>
                      </div>
                    </div>
                    <input
                      type="search"
                      placeholder="What're you searching for?"
                      aria-describedby="button-addon4"
                      className="form-control bg-none border-0 rounded-pill searchBar"
                      onChange={handleSearch}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body card-overflow">
            <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

            <table class="table table-striped">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Agency</th>
                  <th>Agency Code</th>

                  <th>Bonus Or Penalty</th>
                  <th>Payment Gateway</th>
                  <th>Description</th>
                  <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                    RCoin {coinSort ? " ▼" : " ▲"}
                  </th>
                  <th>
                    {setting.currency === "£"
                      ? "GBP(£)"
                      : setting.currency === "$"
                      ? "Dollar($)"
                      : "Rupee(₹)"}
                  </th>
                  <th>Accepted date</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0 ? (
                  data.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{(activePage - 1) * rowsPerPage + index + 1}</td>
                        <td>
                          <span className="d-flex align-items-center">
                            {data?.agency?.name ? data?.agency?.name : "-"}
                          </span>
                        </td>
                        <td>{data?.agency?.agencyCode}</td>

                        <td>
                          {data?.bonus === 0 ? (
                            <span className="text-danger">{data?.penalty}</span>
                          ) : data?.penalty === 0 ? (
                            <span className="text-success">{data?.bonus}</span>
                          ) : (
                            0
                          )}
                        </td>
                        <td>{data?.paymentGateway}</td>

                        <td>{data?.description}</td>
                        <td>{data?.rCoin}</td>
                        <td>{data?.amount}</td>
                        <td>{dayjs(data.updatedAt).format("DD MMM, YYYY")}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="14" align="center">
                      Nothing to show!!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              activePage={activePage}
              rowsPerPage={rowsPerPage}
              userTotal={total}
              handleRowsPerPage={handleRowsPerPage}
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptedRedeem;
