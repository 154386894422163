import React, { useEffect, useState } from "react";

//react-redux
import { useSelector, useDispatch } from "react-redux";

//action
import { getAgencyWiseRedeem } from "../../../store/redeem/action";
import Male from "../../../assets/images/male.png";
import $ from "jquery";
//MUI
import TablePaginationActions from "../../../pages/Pagination";
//dayjs
import dayjs from "dayjs";

import arraySort from "array-sort";
import { getAgencyProfile } from "../../../store/agency/action";

//sweet alert

const PendingRedeemTable = (props) => {
  const dispatch = useDispatch();
  // const { agency } = useSelector((state) => state.agency);
  const agency = localStorage.getItem("AGENCY");
  const parsedAgency = JSON.parse(agency);
  const { userRedeem, totalUserRedeem } = useSelector(
    (state) => state.myRedeem
  );

  const [coinSort, setCoinSort] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(getAgencyProfile(parsedAgency?._id));
  }, [dispatch, parsedAgency]);

  useEffect(() => {
    dispatch(
      getAgencyWiseRedeem(parsedAgency?._id, page, rowsPerPage, "pending")
    );
  }, [parsedAgency?._id, page, rowsPerPage, "pending"]);

  useEffect(() => {
    setData(userRedeem);
  }, [userRedeem]);
  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setRowsPerPage(value);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toUpperCase()
      ? e.target.value.trim().toUpperCase()
      : e.target.value.trim();
    if (value) {
      const data = userRedeem.filter((data) => {
        return (
          data?.userId?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.paymentGateway?.toUpperCase()?.indexOf(value) > -1 ||
          data?.description?.toUpperCase()?.indexOf(value) > -1 ||
          data?.rCoin?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      return setData(userRedeem);
    }
  };

  const handleCoinSort = () => {
    setCoinSort(!coinSort);
    arraySort(data, "rCoin", { reverse: coinSort });
  };

  return (
    <>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0"></div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped custom-table">
                <thead className="text-white">
                  <tr>
                    <th>No.</th>
                    <th>User</th>
                    <th>Payment Gateway</th>
                    <th>Description</th>
                    <th onClick={handleCoinSort} style={{ cursor: "pointer" }}>
                      RCoin {coinSort ? " ▼" : " ▲"}
                    </th>
                    <th>Agency Commission (%)</th>
                    <th>Agency Commission Coin</th>

                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="t">
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{(page - 1) * rowsPerPage + index + 1}</td>
                          <td className="d-flex">
                            <img
                              height="50px"
                              width="50px"
                              alt="app"
                              src={data?.image ? data?.image : Male}
                              style={{
                                boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                border: "2px solid #fff",
                                borderRadius: 10,
                                objectFit: "cover",
                                display: "block",
                              }}
                            />
                            <span className="d-flex align-items-center">
                              {data?.userId?.name ? data?.userId?.name : "-"}
                            </span>
                          </td>
                          <td>{data?.paymentGateway}</td>

                          <td className="tableLongText">
                            {data?.description ? data?.description : "-"}
                          </td>
                          <td className="text-danger">{data?.rCoin}</td>
                          <td className="text-success">
                            {data?.agencyCommissionPercent}
                          </td>
                          <td className="text-success">
                            {data?.agencyCommissionCoin}
                          </td>

                          <td>{dayjs(data?.date).format("DD MMM, YYYY")}</td>
                          <td>
                            {data?.status === 1 && (
                              <span className="text-success">Accepted</span>
                            )}
                            {data?.status === 2 && (
                              <span className="text-danger">Declined</span>
                            )}
                            {data?.status === 0 && (
                              <span className="text-warning">Pending</span>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="10" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <TablePaginationActions
                activePage={page}
                rowsPerPage={rowsPerPage}
                userTotal={totalUserRedeem}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingRedeemTable;
