export const SET_AGENCY = "SET_AGENCY";
export const UNSET_AGENCY = "UNSET_AGENCY";

export const GET_AGENCY_PROFILE = "GET_AGENCY_PROFILE";

// ----------------------------------------------------Admin Panel ----------------------------------------------------

export const GET_AGENCY = "GET_AGENCY";

export const CREATE_NEW_AGENCY = "CREATE_NEW_AGENCY";
export const EDIT_AGENCY = "EDIT_AGENCY";
export const ACTIVE_INACTIVE_AGENCY = "ACTIVE_INACTIVE_AGENCY";



export const GET_AGENCY_WISE_HOST = "GET_AGENCY_WISE_HOST";



export const OPEN_AGENCY_DIALOG = "OPEN_AGENCY_DIALOG";
export const CLOSE_AGENCY_DIALOG = "CLOSE_AGENCY_DIALOG";
