import { ACCEPT_AGENCY_REDEEM, CLOSE_BONUS_PANELTY_DIALOGUE, CLOSE_REASON_DIALOGUE, GET_AGENCY_REDEEM, OPEN_BONUS_PANELTY_DIALOGUE, OPEN_REASON_DIALOGUE } from "./type";

const initialState = {
  total: 0,
  agencyRedeem: [],
  dialog: false,
  dialogData: null,
  dialog1: false,
  dialogData1: null,
};

const agencyRedeemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGENCY_REDEEM:
      return {
        ...state,
        agencyRedeem: action.payload.redeem,
        total: action.payload.total,
      };

    case ACCEPT_AGENCY_REDEEM:
      return {
        ...state,
        agencyRedeem: state.agencyRedeem.filter(
          (agencyRedeem) => agencyRedeem?._id !== action?.payload
        ),
      };

    case OPEN_REASON_DIALOGUE:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_REASON_DIALOGUE:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    case OPEN_BONUS_PANELTY_DIALOGUE:
      return {
        ...state,
        dialog1: true,
        dialogData1: action.payload || null,
      };
    case CLOSE_BONUS_PANELTY_DIALOGUE:
      return {
        ...state,
        dialog1: false,
        dialogData1: null,
      };
    default:
      return state;
  }
};

export default agencyRedeemReducer;
