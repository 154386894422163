import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { warning } from "../../util/Alert";
import { UNSET_AGENCY } from "../../store/agency/type";
import { getAgencyProfile } from "../../store/agency/action";
import { Link } from "react-router-dom/cjs/react-router-dom";
import male from "../../assets/images/male.png";
import $ from "jquery";

const TopNavAgency = () => {
  const agencyProfile = useSelector((state) => state.agency.agencyProfile);



  const dispatch = useDispatch();

  const handleDrawer = () => {
    $(".profile-drop-menu").toggleClass("show");
  };

  const closePopup = () => {
    $("body").removeClass("activity-sidebar-show");
  };

  const handleLogout = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_AGENCY });
        window.location.href = "/agencypanel";
      }
    });
  };

  const agencyId = localStorage.getItem("AGENCY");

  useEffect(() => {
    dispatch(getAgencyProfile(JSON.parse(agencyId)?._id));
  }, [dispatch, agencyId]);
  return (
    <>
      <>
        <div class="page-header">
          <nav class="navbar navbar-expand-lg d-flex justify-content-between">
            <div class="header-title flex-fill">
              <a href={() => false} id="sidebar-toggle">
                <i data-feather="arrow-left"></i>
              </a>
            </div>
            <div class="flex-fill" id="headerNav">
              <ul class="navbar-nav">
                <li class="nav-item dropdown mb-2" onClick={handleDrawer}>
                  <a
                    class="nav-link profile-dropdown"
                    href={() => false}
                    id="profileDropDown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={agencyProfile?.image ? agencyProfile?.image : male}
                      alt=""
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                      }}
                    />
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-end profile-drop-menu"
                    aria-labelledby="profileDropDown"
                    style={{ right: 0, left: "auto" }}
                  >
                    <Link
                      class="dropdown-item"
                      to="/agencypanel/agencyProfile"
                      onClick={handleDrawer}
                    >
                      <i data-feather="user"></i>Profile
                    </Link>

                    <div class="dropdown-divider"></div>

                    <a
                      href={() => false}
                      class="dropdown-item"
                      onClick={handleLogout}
                    >
                      <i data-feather="log-out"></i>Logout
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div class="activity-sidebar-overlay"></div>
      </>
    </>
  );
};

export default TopNavAgency;
