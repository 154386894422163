import React, { useEffect } from "react";
import { getAgencyProfile } from "../../store/agency/action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";

const AgencyProfile = () => {
  const { agencyProfile } = useSelector((state) => state.agency);
  const dispatch = useDispatch();
  const agencyId = localStorage.getItem("AGENCY");

  useEffect(() => {
    dispatch(getAgencyProfile(JSON.parse(agencyId)?._id));
  }, [agencyId]);

 
  return (
    <>
      <div className="page-heading">
        <div className="page-title">
          <div className="row">
            <div className="col-12 col-md-6 order-md-1 order-last">
              <h3 className="mb-3 text-light">Profile</h3>
            </div>
            <div className="col-12 col-md-6 order-md-2 order-first">
              <nav
                aria-label="breadcrumb"
                className="breadcrumb-header float-start float-lg-end"
              >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/agencyPanel/dashboard" className="text-danger">
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    profile
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <section id="basic-vertical-layouts">
          <div className="row match-height">
            <div className="col-md-8 col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mt-3">Agency Information</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form autoComplete="off">
                      <div className="form-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label for="first-name-vertical">Name</label>
                              <input
                                type="text"
                                id="first-name-vertical"
                                className="form-control"
                                name="name"
                                value={agencyProfile?.name}
                                disabled
                                readOnly
                              />
                            </div>
                          </div>

                          <div className="col-12 mt-3 mb-3">
                            <div className="form-group">
                              <label for="email-id-vertical">Email</label>
                              <input
                                type="email"
                                id="email-id-vertical"
                                className="form-control"
                                name="email-id"
                                value={agencyProfile?.email}
                                disabled
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mt-3">Profile Image</h4>
                </div>
                <div className="card-content">
                  <div className="card-body">
                    <form className="form form-vertical">
                      <div className="form-body">
                        <div className="row">
                          <div className="avatar avatar-xl">
                            <div className="container mt-2">
                              <img
                                src={agencyProfile?.image}
                                alt=""
                                className="m-auto image mb-2"
                                style={{
                                  width: "140px",
                                  height: "140px",
                                  objectFit: "cover ",
                                  borderRadius: "20px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AgencyProfile;
