import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  BLOCK_UNBLOCK_SWITCH,
  GET_USER,
  GET_HISTORY,
  EDIT_COIN,
  GET_LIVE_USER,
  LIVE_CUT,
  EDIT_NAME,
  IS_ROOM_ADMIN_SWITCH,
} from "./types";
import { baseURL, key } from "../../util/Config";
import { apiInstanceFetch } from "../../util/api";

export const getUser =
  (start, limit, searchValue, sDate, eDate) => (dispatch) => {
    apiInstanceFetch
      .get(
        `user/getUsers?start=${start}&limit=${limit}&search=${searchValue}&startDate=${sDate}&endDate=${eDate}`
      )
      .then((res) => {
        if (res.status) {
          let male, female;
          if (res.maleFemale) {
            res.maleFemale.map((data) => {
              if (data._id === "female") return (female = data.gender);
              if (data._id === "male") return (male = data.gender);
            });
          }
          dispatch({
            type: GET_USER,
            payload: {
              user: res.user,
              activeUser: res.activeUser,
              total: res.total,
              male: male,
              female: female,
            },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const handleBlockUnblockSwitch = (userId) => (dispatch) => {
  axios
    .patch(`user/blockUnblock/${userId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: BLOCK_UNBLOCK_SWITCH, payload: res.data.user });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const userHistory = (data) => (dispatch) => {
  axios
    .post(`history`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_HISTORY, payload: res.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const editCoin = (data) => (dispatch) => {
  axios
    .post(`/user/addLessCoin`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_COIN,
          payload: { data: res.data.user, id: data.userId },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editName = (name, type, id) => (dispatch) => {
  axios
    .patch(`/user/updateUserByAdmin?name=${name}&userId=${id}&type=${type}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_NAME,
          payload: { data: res.data.user, id: id },
        });
        Toast("success", "Update Successful!!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const getLiveUser = () => (dispatch) => {
  apiInstanceFetch
    .get("liveUser/getLiveUserByAdmin")
    .then((res) => {
      console.log(res);
      if (res.status) {
        dispatch({ type: GET_LIVE_USER, payload: res.liveUser });
      }
    })
    .catch((error) => console.log("error", error));
};

// live cut

export const liveCut = (liveStreamingId, userId, username) => (dispatch) => {
  axios
    .post(
      `liveUser/liveStreamingCutByAdmin?liveStreamingId=${liveStreamingId}&userId=${userId}`
    )
    .then((res) => {
      console.log(res.data);
      if (res.data.status) {
        dispatch({ type: LIVE_CUT, payload: liveStreamingId });
        Toast("success", `${username} Live Cut successfully`);
      }
    });
};

export const isRoomAdmin = (id) => (dispatch) => {
  axios.put(`user/isRoomAdmin?userId=${id}`).then((res) => {
    if (res.data.status) {
      dispatch({ type: IS_ROOM_ADMIN_SWITCH, payload: res?.data?.data });
      {
        res?.data?.data?.isRoomAdmin === true
          ? Toast("success", "user now Room Admin successfully!")
          : Toast("success", "user room admin Off successfully!");
      }
      window.location.reload();
    } else {
      Toast("error", res?.data?.message);
    }
  });
};
