import axios from "axios";
import { Toast } from "../../../src/util/Toast";
import * as CoinSellerType from "./type";

// GET coinSeller
export const getCoinSeller = (search) => (dispatch) => {
  axios
    .get(`coinSeller/get?search=${search}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.GET_COINSELLER,
        payload: res.data.coinSeller,
      });
    })
    .catch((error) => console.log(error));
};

// CREATE coinSeller
export const addCoinSeller = (data) => (dispatch) => {
  axios
    .post(`coinSeller?uniqueId=${data?.uniqueId}&coin=${data?.coin}&mobileNumber=${data?.mobileNumber}&countryCode=${data?.countryCode}`)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.ADD_COINSELLER,
          payload: res.data.coinSeller,
        });
        Toast("success", "coinSeller Add Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// UPDATE coinSeller
export const editCoinSeller = (id, fromData) => (dispatch) => {
  axios
    .patch(`coinSeller/${id}`, fromData)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.EDIT_COINSELLER,
          payload: { coinSeller: res.data.coinSeller, id },
        });
        Toast("success", "coinSeller Update Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

// DELETE AGANCY
export const deleteCoinSeller = (id) => (dispatch) => {
  axios
    .patch(`coinSeller/disableEnable/${id}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.DELETE_COINSELLER,
        payload: { data: res.data.coinSeller, id: id },
      });
      Toast("success", res.data.coinSeller?.isActive === true ? "Enable Successfully" : "disable Successfully")
    })
    .catch((error) => console.log(error));
};

// Show coinSeller
export const showCoinSeller = (id) => (dispatch) => {
  axios
    .patch(`coinSeller/show/${id}`)
    .then((res) => {
      dispatch({
        type: CoinSellerType.SHOW_COINSELLER,
        payload: { data: res.data.data, id },
      });
    })
    .catch((error) => console.log(error));
};



// UPDATE coinSeller
export const AddMoneyByAdmin = (data, id) => (dispatch) => {
  axios
    .patch(`coinSeller/adminToSellerCoin/${id}?coin=${data?.coin}`)
    .then((res) => {
      console.log(res.data.coinSeller);
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.ADD_MONEY_BY_ADMIN,
          payload: { coinSeller: res.data.coinSeller, id: id },
        });
        Toast("success", "Add coin Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};

export const MobileNumberByAdmin = (data, id) => (dispatch) => {
  axios
    .patch(`coinSeller/editMobileNumber/${id}?mobileNumber=${data?.mobileNumber}&countryCode=${data?.countryCode}`)
    .then((res) => {
      console.log(res.data.coinSeller);
      if (res.data.status === true) {
        dispatch({
          type: CoinSellerType.MOBILE_NUMBER_BY_ADMIN,
          payload: { coinSeller: res.data.coinSeller, id: id },
        });
        Toast("success", "Mobile Number Change Successfully");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
// GET coinSeller history
export const getCoinSellerHistory = (id) => (dispatch) => {
  axios
    .get(`coinSellerHistory/${id}`)
    .then((res) => {
      console.log(res.data)
      dispatch({
        type: CoinSellerType.GET_COINSELLER_HISTORY,
        payload: res.data,
      });
    })
    .catch((error) => console.log(error));
};


export const deleteSeller = (id) => (dispatch) => {
  axios
    .delete(`coinSeller/delete/${id}`)
    .then((res) => {
      
      console.log(res.data)
      dispatch({
        
        type: CoinSellerType.DELETE_COINSELLER_PERMANENT,
        payload: id,
      });
    })
    .catch((error) => console.log(error));
};

