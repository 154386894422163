import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  CLOSE_COMMISSION_DIALOG,
  CREATE_NEW_COMMISSION,
  DELETE_COMMISSION,
  EDIT_COMMISSION,
  GET_COMMISSION,
} from "./types";
import { apiInstanceFetch } from "../../util/api";

export const getCommission = () => (dispatch) => {
  apiInstanceFetch
    .get(`commissionRate/get`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_COMMISSION, payload: res?.commission });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const createNewCommission = (data) => (dispatch) => {
  axios
    .post(`commissionRate/store`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Commission created successfully!");
        dispatch({ type: CLOSE_COMMISSION_DIALOG });
        dispatch({ type: CREATE_NEW_COMMISSION, payload: res.data?.commission });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editCommission = (commissionId, data) => (dispatch) => {
  axios
    .patch(`commissionRate/update?commissionRateId=${commissionId}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Commission updated successfully!");
        dispatch({ type: CLOSE_COMMISSION_DIALOG });
        dispatch({
          type: EDIT_COMMISSION,
          payload: { data: res.data?.commission, id: commissionId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteCommission = (commissionId) => (dispatch) => {
  axios
    .delete(`commissionRate/delete?commissionRateId=${commissionId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_COMMISSION, payload: commissionId });
        Toast("success", "Commission Deleted successfully!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
