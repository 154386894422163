
export const GET_AGENCY_REDEEM = "GET_AGENCY_REDEEM";

export const ACCEPT_AGENCY_REDEEM = "ACCEPT_AGENCY_REDEEM";

export const OPEN_REASON_DIALOGUE = "OPEN_REASON_DIALOGUE";

export const CLOSE_REASON_DIALOGUE = "CLOSE_REASON_DIALOGUE";

export const OPEN_BONUS_PANELTY_DIALOGUE = "OPEN_BONUS_PANELTY_DIALOGUE";

export const CLOSE_BONUS_PANELTY_DIALOGUE = "CLOSE_BONUS_PANELTY_DIALOGUE";

