import axios from "axios";
import { apiInstanceFetch } from "../../util/api";
import { Toast } from "../../util/Toast";
import { ACCEPT_AGENCY_REDEEM, GET_AGENCY_REDEEM } from "./type";

export const getAgencyRedeem = (type, start, limit) => (dispatch) => {
    apiInstanceFetch
      .get(
        `agencyRedeem/getAgencyRedeem?type=${type}&start=${start}&limit=${limit}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: GET_AGENCY_REDEEM,
            payload: { redeem: res.data, total: res.total },
          });
        } else {
          Toast("error", res.message);
        }
      })
      .catch((error) => {
        console.log(error);
        Toast("error", error.message);
      });
  };
  
  export const acceptAgencyRedeem =
    (id, type, bonus, penalty, reason) => (dispatch) => {
      
      axios
        .patch(
          `agencyRedeem/update?agencyRedeemId=${id}&type=${type}&bonus=${bonus}&penalty=${penalty}&reason=${reason}`
        )
        .then((res) => {
          if (res.data.status) {
            
            dispatch({
              type: ACCEPT_AGENCY_REDEEM,
              payload: id,
            });
  
            type === "decline"
              ? Toast("success", "Decline Success!!")
              : Toast("success", "Accept Success!!");
          } else {
            Toast("error", res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          Toast("error", error.message);
        });
    };
  