import React, { useEffect } from "react";

// routing
import { NavLink as Link } from "react-router-dom";

// alert
import { warning } from "../../util/Alert";

// redux
import { useDispatch, useSelector } from "react-redux";

//MUI
import { makeStyles } from "@material-ui/core";

// jquery
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { UNSET_AGENCY } from "../../store/agency/type";

const useStyles = makeStyles(() => ({
  navLink: {
    "&.active": {
      color: "#E85382 !important",
      fontWeight: 500,
      fontSize: 16,
    },
    "&.active span": {
      color: "#E85382 !important",
      fontWeight: 900,
    },
  },
}));
const NavbarAgency = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogoutAgency = () => {
    const data = warning();
    data.then((isLogout) => {
      if (isLogout) {
        dispatch({ type: UNSET_AGENCY });
        history.push("/agencypanel");
      }
    });
  };

  useEffect(() => {
    $("").addClass("submenu-margin");
  }, []);
  return (
    <>
      <div class="page-sidebar">
        <Link to="/agencyPanel/dashboard">
          <span className="logo text-danger">Gopi Live</span>
        </Link>
        <ul class="list-unstyled accordion-menu">
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Dashboard"
          >
            <Link to="/agencyPanel/dashboard" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="activity"></i>
              </span>
              Dashboard
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Dashboard"
          >
            <Link to="/agencyPanel/user" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="users"></i>
              </span>
              User
            </Link>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Commission"
            className="pointer-cursor"
          >
            <Link to="/agencyPanel/commission" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="database"></i>
              </span>
              Commission
            </Link>
          </li>
          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Coin Seller"
          >
            <Link to="/agencyPanel/myRedeem" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="dollar-sign"></i>
              </span>
              My Redeem
            </Link>
          </li>

          <li
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Coin Seller"
          >
            <Link to="/agencyPanel/userRedeem" className={`${classes.navLink}`}>
              <span className="sidenav__icon">
                <i data-feather="database"></i>
              </span>
              User Redeem
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Profile">
            <Link
              to="/agencyPanel/agencyProfile"
              className={`${classes.navLink}`}
            >
              <span className="sidenav__icon">
                <i data-feather="user"></i>
              </span>
              Profile
            </Link>
          </li>
          <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout">
            <a
              href={() => false}
              onClick={handleLogoutAgency}
              className="add-collapse-margin"
            >
              <i data-feather="log-out"></i>Logout
            </a>
          </li>
        </ul>
        <a
          href={() => false}
          id="sidebar-collapsed-toggle"
          style={{ opacity: 0, pointerEvents: "none" }}
        >
          <i data-feather="arrow-right"></i>
        </a>
      </div>
    </>
  );
};

export default NavbarAgency;
