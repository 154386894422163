import React, { useEffect, useState } from "react";

//react-redux
import { useSelector, connect, useDispatch } from "react-redux";

//routing
import { Link } from "react-router-dom";

import arraySort from "array-sort";

//action
import { getSetting } from "../../store/setting/action";
import AgencyRedeemCreate from "../../component/dialog/agencyPanel/AgencyRedeemCreate";

//MUI
import { TablePagination } from "@material-ui/core";

//dayjs
import dayjs from "dayjs";
import TablePaginationActions from "../Pagination";
import { getMyRedeem } from "../../store/myRedeem/action";
import { OPEN_NEW_REDEEM_DIALOG } from "../../store/redeem/types";

const MyRedeem = () => {
  const dispatch = useDispatch();
  // const { agency } = useSelector((state) => state.agency);
  const { myRedeem, totalMyRedeem, totalRevenue } = useSelector(
    (state) => state.myRedeem
  );
  const setting = useSelector((state) => state.setting);

  const [data, setData] = useState([]);
  const [coinSort, setCoinSort] = useState(true);
  const [historyType, setHistoryType] = useState("pending");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const agency = localStorage.getItem("AGENCY");
  const parsedAgency = JSON.parse(agency);


  useEffect(() => {
    dispatch(getMyRedeem(parsedAgency?._id, historyType, page, rowsPerPage));
    dispatch(getSetting());
  }, [parsedAgency?._id, historyType, page, rowsPerPage]);

  useEffect(() => {
    setData(myRedeem);
  }, [myRedeem]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setRowsPerPage(value);
  };

  const handleCoinSort = () => {
    setCoinSort(!coinSort);
    arraySort(data, "rCoin", { reverse: coinSort });
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_NEW_REDEEM_DIALOG });
    setHistoryType("pending");
  };
  const handleEdit = (data) => {
    dispatch({ type: OPEN_NEW_REDEEM_DIALOG, payload: data });
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-white"> My Redeem</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/agencyPanel/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  My Redeem
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 float-right mt-3 mt-lg-0 mt-xl-0 float-right">
                  <button
                    type="button"
                    className="btn waves-effect waves-light btn-danger btn-sm float-left"
                    onClick={handleOpen}
                    id="bannerDialog"
                  >
                    <i className="fa fa-plus"></i>
                    <span className="icon_margin">New</span>
                  </button>
                </div>
                <div
                  style={{ height: "80px", paddingTop: "20px" }}
                  className=" col-xs-12 col-sm-12 col-md-12 col-lg-12 float-right mt-3 mt-lg-0 mt-xl-0  align-sm-left d-md-flex d-lg-flex justify-content-end"
                >
                  <div
                    className="d-flex pt-2"
                    id="manageVideoFeed"
                    style={{ width: "100%", overflowX: "scroll" }}
                  >
                    <div className="px-2  my-2 my-md-0">
                      <a
                        href={() => false}
                        className={` pointer-cursor badge bg-secondary ${
                          historyType === "pending"
                            ? "pending-redeemButton"
                            : ""
                        }`}
                        onClick={() => {
                          setHistoryType("pending");
                          setPage(1);
                        }}
                      >
                        Pending
                      </a>
                    </div>

                    <div className="px-2 my-2 my-md-0">
                      <a
                        href={() => false}
                        className={` pointer-cursor badge bg-warning ${
                          historyType === "accept" ? "pending-redeemButton" : ""
                        }`}
                        onClick={() => {
                          setHistoryType("accept");
                          setPage(1);
                        }}
                      >
                        Accept
                      </a>
                    </div>
                    <div className="px-2 my-2 my-md-0">
                      <a
                        href={() => false}
                        className={` pointer-cursor badge bg-danger ${
                          historyType === "decline"
                            ? "pending-redeemButton"
                            : ""
                        }`}
                        onClick={() => {
                          setHistoryType("decline");
                          setPage(1);
                        }}
                      >
                        Decline
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow p-3 m-2 tableShowRedeem">
              <div class="d-sm-flex align-items-center justify-content-between mb-1"></div>
              {historyType === "pending" && (
                <>
                  <div className="d-flex justify-content-between">
                    <h4 className="text-warning revenue">Pending Redeem</h4>
                    <h4 className="text-warning revenue">
                      Total Revenue : {totalRevenue}
                    </h4>
                  </div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Payment Gateway</th>
                        <th>Description</th>
                        <th
                          onClick={handleCoinSort}
                          style={{ cursor: "pointer" }}
                        >
                          Coin {coinSort ? " ▼" : " ▲"}
                        </th>

                        <th>BonusOrPenalty</th>

                        <th>Final</th>
                        <th>
                          {setting?.currency === "฿"
                            ? "Baht(฿)"
                            : setting?.currency === "$"
                            ? "Dollar($)"
                            : "Rupee(₹)"}
                        </th>
                        <th>Date</th>
                        {/* <th>Status</th> */}
                        {/* <th>Edit</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * rowsPerPage + index + 1}</td>
                              <td>{data?.paymentGateway}</td>
                              <td className="tableLongText">
                                {data?.description ? data?.description : "-"}
                              </td>
                              <td className="text-success">
                                {data?.rCoin ? data?.rCoin : "0"}
                              </td>

                              <td>
                                {data?.bonus === 0 ? (
                                  <span className="text-danger">
                                    {data?.penalty}
                                  </span>
                                ) : data?.penalty === 0 ? (
                                  <span className="text-success">
                                    {data?.bonus}
                                  </span>
                                ) : (
                                  0
                                )}
                              </td>

                              <td className="text-primary">
                                {data?.finalCoin}
                              </td>
                              <td className="text-warning">{data?.amount}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {dayjs(data?.date).format("DD MMM, YYYY")}
                              </td>

                              {/* <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-info"
                                  onClick={() => handleEdit(data)}
                                >
                                  <i className="fa fa-edit fa-lg"></i>
                                </button>
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="16" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {historyType === "accept" && (
                <>
                  <div className="d-flex justify-content-between">
                    <h4 className="text-warning revenue">Accept Redeem</h4>
                    <h4 className="text-warning revenue">
                      Total Revenue : {totalRevenue}
                    </h4>
                  </div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Payment Gateway</th>
                        <th>Description</th>
                        <th
                          onClick={handleCoinSort}
                          style={{ cursor: "pointer" }}
                        >
                          Coin {coinSort ? " ▼" : " ▲"}
                        </th>

                        <th>BonusOrPenalty</th>

                        <th>Final</th>
                        <th>
                          {setting?.currency === "฿"
                            ? "Baht(฿)"
                            : setting?.currency === "$"
                            ? "Dollar($)"
                            : "Rupee(₹)"}
                        </th>
                        {/* <th>Status</th> */}
                        <th>Date</th>
                        <th>Accepted Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * rowsPerPage + index + 1}</td>
                              <td>{data?.paymentGateway}</td>
                              <td className="tableLongText">
                                {data?.description ? data?.description : "-"}
                              </td>
                              <td className="text-success">
                                {data?.rCoin ? data?.rCoin : "0"}
                              </td>

                              <td>
                                {data?.bonus === 0 ? (
                                  <span className="text-danger">
                                    {data?.penalty}
                                  </span>
                                ) : data?.penalty === 0 ? (
                                  <span className="text-success">
                                    {data?.bonus}
                                  </span>
                                ) : (
                                  0
                                )}
                              </td>

                              <td className="text-primary">
                                {data?.finalCoin}
                              </td>
                              <td className="text-warning">{data?.amount}</td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {dayjs(data?.date).format("DD MMM, YYYY")}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {dayjs(data?.acceptDeclineDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="16" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              {historyType === "decline" && (
                <>
                  <h4 className="text-danger revenue">Decline Redeem</h4>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Payment Gateway</th>
                        <th>Description</th>
                        <th
                          onClick={handleCoinSort}
                          style={{ cursor: "pointer" }}
                        >
                          Coin {coinSort ? " ▼" : " ▲"}
                        </th>

                        <th>BonusOrPenalty</th>

                        <th>Final</th>
                        <th>
                          {setting?.currency === "฿"
                            ? "Baht(฿)"
                            : setting?.currency === "$"
                            ? "Dollar($)"
                            : "Rupee(₹)"}
                        </th>
                        <th>Decline Reason</th>
                        <th>Date</th>
                        <th>Decline Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.length > 0 ? (
                        data?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{(page - 1) * rowsPerPage + index + 1}</td>
                              <td>{data?.paymentGateway}</td>
                              <td className="tableLongText">
                                {data?.description ? data?.description : "-"}
                              </td>
                              <td className="text-success">
                                {data?.rCoin ? data?.rCoin : "0"}
                              </td>

                              <td className="text-danger">
                                {data?.bonus === 0 ? (
                                  <span className="text-danger">
                                    {data?.penalty}
                                  </span>
                                ) : data?.penalty === 0 ? (
                                  <span className="text-success">
                                    {data?.bonus}
                                  </span>
                                ) : (
                                  0
                                )}
                              </td>

                              <td className="text-primary">
                                {data?.finalCoin}
                              </td>
                              <td className="text-warning">{data?.amount}</td>
                              <td>
                                {data?.declineReason
                                  ? data?.declineReason
                                  : "-"}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {dayjs(data?.date).format("DD MMM, YYYY")}
                              </td>
                              <td style={{ whiteSpace: "nowrap" }}>
                                {dayjs(data?.acceptDeclineDate).format(
                                  "DD MMM, YYYY"
                                )}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="16" align="center">
                            Nothing to show!!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </>
              )}
              <TablePaginationActions
                activePage={page}
                rowsPerPage={rowsPerPage}
                userTotal={totalMyRedeem}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <AgencyRedeemCreate />
    </>
  );
};

export default MyRedeem;
