import React from "react";
import { Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";

const AuthRouter = ({ component: Component, isAuth2, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuth2 === true ? (
        <Redirect to="/agecyPanel" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  isAuth2: state?.agency?.isAuth2,
});

export default connect(mapStateToProps)(AuthRouter);
