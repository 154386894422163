import { key } from "../../util/Config";
import setDevKey from "../../util/SetDevKey";
import setToken from "../../util/SetToken";
import {
  ACTIVE_INACTIVE_AGENCY,
  CLOSE_AGENCY_DIALOG,
  CREATE_NEW_AGENCY,
  EDIT_AGENCY,
  GET_AGENCY,
  GET_AGENCY_PROFILE,
  GET_AGENCY_WISE_HOST,
  OPEN_AGENCY_DIALOG,
  SET_AGENCY,
  UNSET_AGENCY,
} from "./type";
import jwt_decode from "jwt-decode";

const initialState = {
  isAuth2: false,
  agency: {},
  total: 0,
  dialog: false,
  dialogData: null,
};

const agencyReducer = (state = initialState, action) => {
  let decoded;

  switch (action.type) {
    case SET_AGENCY:
      if (action.payload) {
        decoded = jwt_decode(action.payload);
      }
      setToken(action.payload);
      setDevKey(key);
      localStorage.setItem("AGENCY", JSON.stringify(decoded));
      localStorage.setItem("TOKEN", action.payload);
      localStorage.setItem("KEY", key);
      localStorage.setItem("isAuth2", true);

      return {
        ...state,
        isAuth2: true,
        agency: decoded,
      };

    case UNSET_AGENCY:
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("KEY");

      setDevKey(null);
      setToken(null);
      return {
        ...state,
        isAuth2: false,
        agency: {},
      };

      case GET_AGENCY_PROFILE:
        
        return{
          ...state,
          agencyProfile: action.payload
        }

    case GET_AGENCY:
      return {
        ...state,
        agency: action?.payload?.agency,
        total: action?.payload?.total,
      };

    case CREATE_NEW_AGENCY:
      const data = [...state.agency];
      data.unshift(action.payload);
      return {
        ...state,
        agency: data,
      };

    case EDIT_AGENCY:
      return {
        ...state,
        agency: state.agency.map((agency) => {
          if (agency._id === action.payload.id) return action.payload.data;
          else return agency;
        }),
      };

    case ACTIVE_INACTIVE_AGENCY:
      return {
        ...state,
        agency: state.agency.map((agency) => {
          if (agency?._id === action?.payload?._id)
            return {
              ...agency,
              isActive: action?.payload?.isActive,
            };
          else return agency;
        }),
      };
    case GET_AGENCY_WISE_HOST:
      return {
        ...state,
        agencyWiseHost: action.payload.data,
        totalAgencyWiseHost: action.payload.total,
      };
    case OPEN_AGENCY_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_AGENCY_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };
    default:
      return state;
  }
};
export default agencyReducer;
