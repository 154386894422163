export const GET_GAME = "GET_GAME";
export const GAME_SETTING_ID = "GAME_SETTING_ID";
export const CREATE_NEW_GAME = "CREATE_NEW_GAME";
export const EDIT_GAME = "EDIT_GAME";
export const DELETE_GAME = "DELETE_GAME";

export const ACTIVE_GAME = "ACTIVE_GAME";

export const OPEN_GAME_DIALOG = "OPEN_GAME_DIALOG";
export const CLOSE_GAME_DIALOG = "CLOSE_GAME_DIALOG";
